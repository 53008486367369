#releases {
  .release {
    @apply max-w-prose mx-auto;

    &__heading {
      @apply text-center mb-4;

      h2 {
        @apply mb-0;
      }
      
      small {
        @apply text-gray-500;
      }
    }

    &__wrapper {
      @apply border bg-white shadow rounded-lg p-4 lg:p-8 space-y-8;
    }

    &__feature {
      &__heading {
        @apply flex items-center border-b mb-6 pb-1 space-x-4;

        h3 {
          @apply my-0;
        }
      }

      &__label {
        @apply rounded-lg text-white uppercase bg-primary-500 text-xs font-semibold px-2 py-1 font-mono;
      }
      

      ul {
        @apply space-y-3 text-gray-500 list-disc pl-4;
      }
    }
  }

  .releases__newsletter {
    @apply relative left-1/2 mx-auto w-screen bg-gray-50 py-20 px-8 lg:px-0;
    margin-left: -50vw;

    #iframe-sib {
      @apply block mx-auto w-full;
      height: 580px;
    }
  }
}
