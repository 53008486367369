.steps {
  &__tabs {
    @apply flex justify-center mb-8;

    ul {
      @apply flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8;
    }

    a {
      @apply lg:text-lg text-gray-900 cursor-pointer block border-b-2 py-2 lg:px-4 px-2 font-semibold hover:text-primary-500 hover:border-primary-500 hover:no-underline transition;

      &.is-active {
        @apply border-primary-500 text-primary-500;
      }
    }
  }

  &__item {
    @apply max-w-2xl mx-auto;

    &__text {
      @apply mb-4 md:mb-16 text-gray-500 leading-relaxed;
    }

    &__image {
      @apply mx-auto h-80;
    }
  }
}
