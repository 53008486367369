.accordion {
  @apply space-y-4;

  &__item {
    @apply block pl-6 relative;

    &__title {
      @apply font-semibold text-xl cursor-pointer hover:text-primary-500 transition;

      &::marker {
        @apply hidden;
        content: "";
      }

      &::before {
        @apply block h-4 w-4 bg-no-repeat absolute left-0 top-1 transform transition;
        content: "";
        background-position: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 5l7 7-7 7' /%3E%3C/svg%3E");
      }
    }

    &__content {
      @apply relative overflow-hidden transition-all max-h-0 duration-700;
    }

    p {
      @apply my-2 text-base lg:text-lg text-gray-500;
    }

    &.is-active {
      @apply text-primary-500;
      
      .accordion__item__title::before {
        @apply rotate-90;
      }
    }
  }
}
