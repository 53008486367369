.btn {
  @apply inline-block w-full lg:flex lg:w-auto py-4 px-8 text-white bg-primary-500 hover:bg-primary-700 font-bold rounded-lg shadow cursor-pointer transition ease-in-out;

  &-menu {
    @apply py-3 px-6 leading-none w-auto float-right;
  }

  &-contactWizard {
    @apply py-2 px-3 md:px-5;
  }

  &-primary {
    @apply text-white bg-primary-500 hover:bg-primary-700;
  }

  &-primary-outlined {
    @apply focus:outline-none text-center text-primary-500 bg-white hover:text-white hover:bg-primary-500 border border-primary-500;
  }

  &-primary-light {
    @apply text-primary-500 bg-primary-50 hover:bg-primary-100 shadow-none;
  }

  &-arrow {
    @apply ml-2 transform ease-in-out transition-transform;
  }
}
