.solutions {
  @apply lg:max-w-6xl mx-auto pb-20;
  &__background {
    @apply mx-auto -mb-8;

    @screen lg {
      @apply -ml-12;
      margin-bottom: -90%;
      max-width: 115%;
      width: 115%;
    }

    img {
      @apply block w-full max-h-72;
    }
  }
  &__wrapper {
    @apply grid lg:gap-36 grid-cols-1;

    > div:first-child {
      @apply order-last mb-24;
    }

    > div:nth-child(2) {
      @apply -ml-16;
    }

    @screen lg {
      > div:first-child {
        @apply order-first mb-0;
      }
      grid-template-columns: 1fr 1.3fr;
    }
  }

  &__headline {
    @apply text-left;
  }

  &__text {
    @apply text-gray-500;

    ul {
      @apply mt-4 lg:pl-10 space-y-2;

      li {
        @apply flex items-center content-start;

        svg {
          @apply text-primary-500 flex-shrink-0 place-self-start h-6 w-6 mr-2;
        }
      }
    }
  }
}
