.main-navigation {
  @apply fixed top-0 left-0 w-screen right-0 z-40 backdrop-blur-3xl backdrop-opacity-100 flex flex-wrap items-center justify-between py-2 md:py-5;

  &__inner {
    @apply flex max-w-6xl w-screen mx-auto px-4 justify-between;
  }
  &__logo-wrapper {
    @apply flex flex-shrink-0;
  }

  &__logo-schrift {
    @apply mt-1;
  }

  &__logo {
    @apply mt-2 text-xl text-primary-500 font-semibold;
  }

  &__mobile-navbar {
    @apply block lg:hidden;

    &__burger {
      @apply flex items-center py-2 text-primary-500;

      svg {
        @apply h-6 w-6 focus:outline-none;
      }
    }
  }

  &__navbar {
    @apply flex flex-grow items-center w-auto text-base pr-4;

    &__links {
      @apply ml-auto space-x-6;

      a {
        @apply font-semibold inline-block mt-0 border-b-2 border-transparent text-primary-900 hover:text-primary-300 hover:border-primary-300 cursor-pointer hover:no-underline;

        &.active {
          @apply border-primary-900;
        }
      }
    }

    &__button {
      @apply ml-6;
    }
  }

  &__mobile-menu {
    @apply w-full text-right;
    &__links {
      @apply space-y-4 flex flex-col items-end;
      a {
        @apply w-max block border-b-2 font-semibold border-transparent text-primary-900 hover:text-primary-300 cursor-pointer;

        &.active {
          @apply border-primary-900;
        }
      }
    }
    &__button {
      button {
        @apply mt-4;
      }
    }
  }
}
