.pricing {
  @apply flex justify-center items-center py-20;

  &__wrapper {
    @apply flex flex-col items-center md:max-w-5xl mx-auto text-center;
  }

  &__headline {
    @include headline();

    &__subline {
      @include subline();
    }
  }

  &__content {
    @apply mx-auto flex flex-col lg:flex-row items-start;

    &__item {
      @apply flex w-full lg:w-2/5 bg-white rounded-lg shadow-lg p-6 lg:p-12 self-stretch space-x-4 text-left;

      &__icon {
        svg {
          @apply h-12 w-12 p-1 text-primary-500;
        }
      }

      &__text {
        h3 {
          @apply mt-2;
        }

        p {
          @apply text-gray-500;
        }
      }
    }

    &__plus {
      @apply text-primary-500 flex-shrink-0 w-full lg:w-1/5 justify-center flex my-8 lg:my-auto;

      svg {
        @apply w-16 h-16;
      }
    }
  }

  &__cta {
    @apply mt-20;

    &__button {
      @apply mt-4;

      button {
        @apply w-full lg:w-auto mx-auto;
      }
    }
  }
}
