.features {
  @apply relative left-1/2 mx-auto w-screen bg-gray-50 py-20 px-4 lg:px-0;
  margin-left: -50vw;

  &__wrapper {
    @apply flex flex-col items-center md:max-w-5xl mx-auto text-center;
  }

  &__headline {
    @include headline();

    &__subline {
      @include subline();
    }
  }

  &__items {
    @apply grid gap-y-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-6 md:gap-6;
  }

  &__coming-soon {
    &__nav {
      @apply mb-10 mt-16 mx-auto flex justify-center items-center space-x-3;

      &.active {
        @apply relative;

        span {
          @apply border-primary-500 text-primary-500;
        }

        svg {
          @apply text-primary-500;
        }
      }

      svg {
        @apply w-6 h-6 cursor-pointer border-b-2 border-transparent transition;
      }

      span {
        @apply border-b-2 border-transparent font-bold cursor-pointer transition;
      }
    }
  }
}
