.cta {
  @apply mt-20;

  &__button {
    @apply mt-4;

    button {
      @apply mx-auto;
    }
  }
}
