.features {
  &__item {
    @apply flex flex-col text-center lg:col-span-2 bg-white shadow rounded-lg p-4 lg:p-8;

    &:first-child,
    &:nth-child(2) {
      @apply lg:col-span-3;
    }

    > svg {
      @apply text-primary-500 bg-opacity-50 w-12 h-12 my-2 mx-auto flex-shrink-0;
    }

    > img {
      @apply text-primary-500 bg-opacity-50 w-12 h-12 my-2 mx-auto flex-shrink-0;
    }

    p {
      @apply text-gray-500 leading-relaxed;
    }

    &__button {
      @apply hover:no-underline mt-auto;

      button {
        @apply mx-auto mb-2 lg:mb-0;
      }
    }
  }
}
