.impressions {
  @apply relative mx-auto flex justify-center items-center;

  &__background {
    @include background(from-primary);
  }

  &__image {
    @apply relative mx-auto z-10 p-4 md:p-6 lg:p-10 pb-0 md:pb-0 lg:pb-0;

    img {
      @apply mx-auto w-full rounded-lg shadow;
    }
  }
}
