*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Nexa, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.top-10 {
  top: 2.5rem;
}

.right-12 {
  right: 3rem;
}

.z-50 {
  z-index: 50;
}

.col-start-2 {
  grid-column-start: 2;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.flex {
  display: flex;
}

.h-full {
  height: 100%;
}

.h-3 {
  height: .75rem;
}

.w-full {
  width: 100%;
}

.w-3 {
  width: .75rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.place-content-center {
  align-content: center;
  justify-content: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.justify-self-start {
  justify-self: start;
}

.overflow-scroll {
  overflow: scroll;
}

.rounded {
  border-radius: .25rem;
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(34, 197, 94, var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 109, 193, var(--tw-bg-opacity));
}

.bg-black\/75 {
  background-color: rgba(0, 0, 0, .75);
}

.p-0 {
  padding: 0;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-start:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.text-start:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.text-start:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(34, 197, 94, var(--tw-border-opacity));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:hover .group-hover\:translate-x-1 {
  --tw-translate-x: .25rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:border-2 {
  border-width: 2px;
}

.group:hover .group-hover\:border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
}

.group:hover .group-hover\:bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
}

.group:hover .group-hover\:text-primary-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-5 {
    height: 1.25rem;
  }

  .sm\:w-5 {
    width: 1.25rem;
  }
}

@media (min-width: 768px) {
  .md\:top-12 {
    top: 3rem;
  }

  .md\:right-14 {
    right: 3.5rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:max-w-6xl {
    max-width: 72rem;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:text-left {
    text-align: left;
  }

  .group:hover .md\:group-hover\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (min-width: 1024px) {
  .lg\:top-20 {
    top: 5rem;
  }

  .lg\:right-20 {
    right: 5rem;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  overflow-x: hidden;
}

main {
  flex: 1;
}

.container {
  min-height: 100vh;
  max-width: 72rem;
  flex-direction: column;
  margin-top: 7rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

h1 {
  margin-bottom: 1.5rem;
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 1.25;
}

@media (min-width: 640px) {
  h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 2.25rem;
}

@media (min-width: 640px) {
  h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

h3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

a {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

a:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 38, 134, var(--tw-text-opacity));
}

section {
  padding: 3rem 1rem;
}

@media (min-width: 1024px) {
  section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

[x-cloak] {
  display: none !important;
}

strong {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  font-weight: 700;
}

.text-highlight {
  --tw-text-opacity: 1;
  color: rgba(51, 89, 185, var(--tw-text-opacity));
  font-weight: 600;
}

.btn {
  width: 100%;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  padding: 1rem 2rem;
  font-weight: 700;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 38, 134, var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  .btn {
    width: auto;
    display: flex;
  }
}

.btn-menu {
  float: right;
  width: auto;
  padding: .75rem 1.5rem;
  line-height: 1;
}

.btn-contactWizard {
  padding: .5rem .75rem;
}

@media (min-width: 768px) {
  .btn-contactWizard {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 38, 134, var(--tw-bg-opacity));
}

.btn-primary-outlined {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.btn-primary-outlined:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn-primary-outlined:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.btn-primary-light {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 234, 246, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.btn-primary-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 213, 237, var(--tw-bg-opacity));
}

.btn-arrow {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  margin-left: .5rem;
  transition-property: -webkit-transform, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Nexa;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("36EE5B_1_0.6293cc50.eot");
  src: url("36EE5B_1_0.6293cc50.eot#iefix") format("embedded-opentype"), url("36EE5B_1_0.1d297e9d.woff2") format("woff2"), url("36EE5B_1_0.e899a5cd.woff") format("woff"), url("36EE5B_1_0.c8612418.ttf") format("truetype");
}

@font-face {
  font-family: Nexa;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("36EE5B_7_0.0074aaa3.eot");
  src: url("36EE5B_7_0.0074aaa3.eot#iefix") format("embedded-opentype"), url("36EE5B_7_0.20a0299e.woff2") format("woff2"), url("36EE5B_7_0.a513e453.woff") format("woff"), url("36EE5B_7_0.bd344df6.ttf") format("truetype");
}

@font-face {
  font-family: Nexa;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("36EE5B_F_0.32c5fe68.eot");
  src: url("36EE5B_F_0.32c5fe68.eot#iefix") format("embedded-opentype"), url("36EE5B_F_0.ca2d3834.woff2") format("woff2"), url("36EE5B_F_0.a8962eb0.woff") format("woff"), url("36EE5B_F_0.b7c4c80d.ttf") format("truetype");
}

@font-face {
  font-family: Nexa;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("36EE5B_3_0.ce25fe22.eot");
  src: url("36EE5B_3_0.ce25fe22.eot#iefix") format("embedded-opentype"), url("36EE5B_3_0.be494f2d.woff2") format("woff2"), url("36EE5B_3_0.c4c21e7b.woff") format("woff"), url("36EE5B_3_0.f70e4b65.ttf") format("truetype");
}

@font-face {
  font-family: Nexa;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("36EE5B_B_0.d7c622de.eot");
  src: url("36EE5B_B_0.d7c622de.eot#iefix") format("embedded-opentype"), url("36EE5B_B_0.4fc89a52.woff2") format("woff2"), url("36EE5B_B_0.55f7f1c5.woff") format("woff"), url("36EE5B_B_0.614ed641.ttf") format("truetype");
}

@font-face {
  font-family: Nexa;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("36EE5B_B_0.d7c622de.eot");
  src: url("36EE5B_5_0.4148cc34.eot#iefix") format("embedded-opentype"), url("36EE5B_5_0.302bdd30.woff2") format("woff2"), url("36EE5B_5_0.4173b556.woff") format("woff"), url("36EE5B_5_0.ae17e163.ttf") format("truetype");
}

#flexibility .flexibility__examples {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 4rem;
  display: grid;
}

@media (min-width: 1024px) {
  #flexibility .flexibility__examples {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

#flexibility .flexibility__examples__column h2 {
  text-align: left;
}

#integration .academy {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  #integration .academy {
    flex-direction: row;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 2.5rem;
  }
}

#integration .academy__image {
  width: auto;
  flex-shrink: 0;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #integration .academy__image {
    width: 50%;
    margin-top: 0;
    margin-bottom: -18rem;
  }
}

#integration .academy__content {
  text-align: center;
}

@media (min-width: 1024px) {
  #integration .academy__content {
    text-align: left;
  }
}

#integration .academy__content h3 {
  margin-top: 0;
}

#integration .academy__content p {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  #integration .academy__content p {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

#integration .academy__content button {
  margin-top: 1.5rem;
}

@media (min-width: 1024px) {
  #integration .academy__content button {
    margin-top: 1rem;
  }
}

.pricing_new {
  width: 100vw;
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5rem 2rem;
  display: flex;
  position: relative;
  left: 50%;
}

@media (min-width: 1024px) {
  .pricing_new {
    padding-left: 0;
    padding-right: 0;
  }
}

.pricing_new {
  margin-left: -50vw;
}

.bookings-slider {
  accent-color: #002fa7;
}

#pricing section {
  padding-top: .5rem;
}

[x-cloak] {
  display: none !important;
}

#releases .release {
  max-width: 65ch;
  margin-left: auto;
  margin-right: auto;
}

#releases .release__heading {
  text-align: center;
  margin-bottom: 1rem;
}

#releases .release__heading h2 {
  margin-bottom: 0;
}

#releases .release__heading small {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

#releases .release__wrapper > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

#releases .release__wrapper {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-width: 1px;
  border-radius: .5rem;
  padding: 1rem;
}

@media (min-width: 1024px) {
  #releases .release__wrapper {
    padding: 2rem;
  }
}

#releases .release__feature__heading {
  align-items: center;
  margin-bottom: 1.5rem;
  display: flex;
}

#releases .release__feature__heading > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

#releases .release__feature__heading {
  border-bottom-width: 1px;
  padding-bottom: .25rem;
}

#releases .release__feature__heading h3 {
  margin-top: 0;
  margin-bottom: 0;
}

#releases .release__feature__label {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: .5rem;
  padding: .25rem .5rem;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1rem;
}

#releases .release__feature ul {
  list-style-type: disc;
}

#releases .release__feature ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

#releases .release__feature ul {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  padding-left: 1rem;
}

#releases .releases__newsletter {
  width: 100vw;
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  padding: 5rem 2rem;
  position: relative;
  left: 50%;
}

@media (min-width: 1024px) {
  #releases .releases__newsletter {
    padding-left: 0;
    padding-right: 0;
  }
}

#releases .releases__newsletter {
  margin-left: -50vw;
}

#releases .releases__newsletter #iframe-sib {
  width: 100%;
  height: 580px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.accordion > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.accordion__item {
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.accordion__item__title {
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.accordion__item__title:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.accordion__item__title::marker {
  content: "";
  display: none;
}

.accordion__item__title:before {
  height: 1rem;
  width: 1rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 5l7 7-7 7' /%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: .25rem;
  left: 0;
}

.accordion__item__content {
  max-height: 0;
  transition-property: all;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
  overflow: hidden;
}

.accordion__item p {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  .accordion__item p {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.accordion__item.is-active {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.accordion__item.is-active .accordion__item__title:before {
  --tw-rotate: 90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cta {
  margin-top: 5rem;
}

.cta__button {
  margin-top: 1rem;
}

.cta__button button {
  margin-left: auto;
  margin-right: auto;
}

.features__item {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  text-align: center;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  flex-direction: column;
  padding: 1rem;
  display: flex;
}

@media (min-width: 1024px) {
  .features__item {
    grid-column: span 2 / span 2;
    padding: 2rem;
  }

  .features__item:first-child, .features__item:nth-child(2) {
    grid-column: span 3 / span 3;
  }
}

.features__item > svg, .features__item > img {
  height: 3rem;
  width: 3rem;
  --tw-bg-opacity: .5;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  flex-shrink: 0;
  margin: .5rem auto;
}

.features__item p {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  line-height: 1.625;
}

.features__item__button {
  margin-top: auto;
}

.features__item__button:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.features__item__button button {
  margin-bottom: .5rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .features__item__button button {
    margin-bottom: 0;
  }
}

.hotspot__spot {
  height: .75rem;
  width: .75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(211, 4, 59, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(.5rem + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  --tw-ring-color: rgba(246, 205, 216, var(--tw-ring-opacity));
  --tw-ring-opacity: .5;
  border-radius: 9999px;
  justify-content: center;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
}

@media (min-width: 640px) {
  .hotspot__spot {
    height: 1rem;
    width: 1rem;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(.75rem + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  }
}

@media (min-width: 1024px) {
  .hotspot__spot {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.hotspot__spot:first-of-type.is-animated {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.hotspot__spot:first-of-type.is-animated .hotspot__spot__ping {
  height: 100%;
  width: 100%;
  display: inline-flex;
  position: absolute;
}

@-webkit-keyframes ping {
  75%, 100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

.hotspot__spot:first-of-type.is-animated .hotspot__spot__ping {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 4, 59, var(--tw-bg-opacity));
  opacity: .5;
  border-radius: 9999px;
  -webkit-animation: 1.5s cubic-bezier(0, 0, .2, 1) infinite ping;
  animation: 1.5s cubic-bezier(0, 0, .2, 1) infinite ping;
}

.hotspot__spot:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(190, 4, 53, var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.hotspot__spot:hover:before {
  visibility: visible;
  opacity: 1;
}

.hotspot__spot.is-active {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 4, 53, var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.hotspot__spot.is-active .hotspot__spot__ping {
  -webkit-animation: none;
  animation: none;
}

.hotspot__spot.is-active:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(169, 3, 47, var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .hotspot__spot.is-active:before {
    visibility: visible;
    opacity: 1;
  }
}

.hotspot__spot:before {
  content: attr(data-tooltip);
  visibility: hidden;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: .8;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  opacity: 0;
  border-radius: .5rem;
  padding: .5rem .75rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: -3rem;
}

.hotspot__close {
  float: right;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 9999px;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (min-width: 640px) {
  .hotspot__close {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.hotspot__close {
  -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
}

.hotspot__close:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.hotspot__content {
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: .8;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: .5rem;
  flex-direction: column;
  padding: 1rem;
  display: flex;
  position: absolute;
  overflow-x: auto;
}

@media (min-width: 640px) {
  .hotspot__content {
    height: auto;
    border-radius: 0;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .hotspot__content {
    padding: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .hotspot__content {
    padding: 2.5rem;
  }
}

.hotspot__content.top {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  top: 0;
}

.hotspot__content.bottom {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  bottom: 0;
}

.hotspot__content h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (min-width: 640px) {
  .hotspot__content h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.hotspot__content p {
  margin-bottom: .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 640px) {
  .hotspot__content p {
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.hotspot__header {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .75rem;
  display: flex;
}

.hotspot__navigation {
  width: 7rem;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 640px) {
  .hotspot__navigation {
    width: 9rem;
  }
}

@media (min-width: 768px) {
  .hotspot__navigation {
    margin-top: 1rem;
  }
}

.hotspot__navigation > div:not(.hotspot__navigation--pagination) {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: 9999px;
  align-items: center;
  padding: .375rem;
  display: flex;
}

.hotspot__navigation > div:not(.hotspot__navigation--pagination):before {
  height: .75rem;
  width: .75rem;
  display: inline-block;
}

@media (min-width: 640px) {
  .hotspot__navigation > div:not(.hotspot__navigation--pagination):before {
    height: 1rem;
    width: 1rem;
  }
}

.hotspot__navigation > div:not(.hotspot__navigation--pagination):before {
  content: "";
}

.hotspot__navigation--pagination {
  --tw-text-opacity: 1;
  color: rgba(163, 163, 163, var(--tw-text-opacity));
  align-items: center;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

@media (min-width: 640px) {
  .hotspot__navigation--pagination {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.hotspot__navigation--prev:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 19l-7-7 7-7' /%3E%3C/svg%3E");
}

.hotspot__navigation--next:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 5l7 7-7 7' /%3E%3C/svg%3E");
}

.steps__tabs {
  justify-content: center;
  margin-bottom: 2rem;
  display: flex;
}

.steps__tabs ul {
  flex-direction: column;
  display: flex;
}

.steps__tabs ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

@media (min-width: 768px) {
  .steps__tabs ul {
    flex-direction: row;
  }

  .steps__tabs ul > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
  }
}

.steps__tabs a {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  border-bottom-width: 2px;
  padding: .5rem;
  font-weight: 600;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.steps__tabs a:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

@media (min-width: 1024px) {
  .steps__tabs a {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.steps__tabs a.is-active {
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.steps__item {
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

.steps__item__text {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  margin-bottom: 1rem;
  line-height: 1.625;
}

@media (min-width: 768px) {
  .steps__item__text {
    margin-bottom: 4rem;
  }
}

.steps__item__image {
  height: 20rem;
  margin-left: auto;
  margin-right: auto;
}

.tabs {
  margin-top: 3rem;
}

.tabs__nav {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;
}

@media (min-width: 1024px) {
  .tabs__nav {
    justify-content: flex-start;
  }
}

.tabs__nav__item {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  align-items: center;
  padding: .5rem;
  font-weight: 600;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.tabs__nav__item:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.tabs__nav__item__title {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tabs__nav__item__icon {
  height: 2rem;
  width: 2rem;
  display: none;
}

@media (min-width: 1024px) {
  .tabs__nav__item__icon {
    display: block;
  }
}

.tabs__nav__item.is-active {
  position: relative;
}

.tabs__nav__item.is-active span {
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  .tabs__nav__item.is-active:after {
    z-index: 10;
    height: 0;
    width: 0;
    content: "";
    border: 1rem solid rgba(0, 0, 0, 0);
    border-top-width: 0;
    border-bottom-color: #f9fafb;
    display: block;
    position: absolute;
    bottom: -1rem;
    left: 50%;
  }
}

.tabs__container {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .tabs__container {
    flex-direction: row;
    padding: 2.5rem;
  }
}

.tabs__container__tab {
  width: 100%;
  text-align: left;
}

.tabs__container__tab h3 {
  margin-top: 0;
}

.tabs__container__tab p, .tabs__container__tab ul {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  .tabs__container__tab p, .tabs__container__tab ul {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.tabs__container__tab ul {
  margin-top: 1.5rem;
}

.tabs__container__tab ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.tabs__container__tab ul li {
  align-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  display: flex;
  position: relative;
}

.tabs__container__tab ul li:before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
}

.tabs__container__tab button {
  margin-top: 1.5rem;
}

@media (min-width: 1024px) {
  .tabs__container__tab button {
    margin-top: 1rem;
  }
}

.main-navigation {
  z-index: 40;
  width: 100vw;
  --tw-backdrop-blur: blur(64px);
  --tw-backdrop-opacity: opacity(1);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .main-navigation {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.main-navigation__inner {
  width: 100vw;
  max-width: 72rem;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.main-navigation__logo-wrapper {
  flex-shrink: 0;
  display: flex;
}

.main-navigation__logo-schrift {
  margin-top: .25rem;
}

.main-navigation__logo {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  margin-top: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.main-navigation__mobile-navbar {
  display: block;
}

@media (min-width: 1024px) {
  .main-navigation__mobile-navbar {
    display: none;
  }
}

.main-navigation__mobile-navbar__burger {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.main-navigation__mobile-navbar__burger svg {
  height: 1.5rem;
  width: 1.5rem;
}

.main-navigation__mobile-navbar__burger svg:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.main-navigation__navbar {
  width: auto;
  flex-grow: 1;
  align-items: center;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
}

.main-navigation__navbar__links {
  margin-left: auto;
}

.main-navigation__navbar__links > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.main-navigation__navbar__links a {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(0, 28, 100, var(--tw-text-opacity));
  border-color: rgba(0, 0, 0, 0);
  border-bottom-width: 2px;
  margin-top: 0;
  font-weight: 600;
  display: inline-block;
}

.main-navigation__navbar__links a:hover {
  --tw-border-opacity: 1;
  border-color: rgba(51, 89, 185, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(51, 89, 185, var(--tw-text-opacity));
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.main-navigation__navbar__links a.active {
  --tw-border-opacity: 1;
  border-color: rgba(0, 28, 100, var(--tw-border-opacity));
}

.main-navigation__navbar__button {
  margin-left: 1.5rem;
}

.main-navigation__mobile-menu {
  width: 100%;
  text-align: right;
}

.main-navigation__mobile-menu__links {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.main-navigation__mobile-menu__links > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.main-navigation__mobile-menu__links a {
  width: -webkit-max-content;
  width: max-content;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(0, 28, 100, var(--tw-text-opacity));
  border-color: rgba(0, 0, 0, 0);
  border-bottom-width: 2px;
  font-weight: 600;
  display: block;
}

.main-navigation__mobile-menu__links a:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 89, 185, var(--tw-text-opacity));
}

.main-navigation__mobile-menu__links a.active {
  --tw-border-opacity: 1;
  border-color: rgba(0, 28, 100, var(--tw-border-opacity));
}

.main-navigation__mobile-menu__button button {
  margin-top: 1rem;
}

footer {
  padding: 2.5rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  footer {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

footer .footer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  footer .footer {
    flex-direction: row;
  }
}

footer .footer__content {
  width: 100%;
  text-align: center;
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  footer .footer__content {
    width: auto;
    text-align: left;
    flex-direction: row;
    margin-right: auto;
  }
}

footer .footer__content address {
  font-style: normal;
}

footer .footer__content a {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

footer .footer__content a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

footer .footer__content__item {
  margin-top: 1rem;
}

@media (min-width: 1024px) {
  footer .footer__content__item {
    margin-top: 0;
    margin-left: 2rem;
  }
}

footer .footer__content__item li:nth-child(1) {
  margin-bottom: .5rem;
}

@media (min-width: 640px) {
  footer .footer__content__item li:nth-child(1) {
    margin-bottom: 0;
  }
}

footer .footer__image {
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

footer .footer__image > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

@media (min-width: 1024px) {
  footer .footer__image {
    margin-top: 0;
  }
}

.contact {
  width: 100vw;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  padding: 5rem 1rem;
  position: relative;
  left: 50%;
}

@media (min-width: 1024px) {
  .contact {
    padding-left: 0;
    padding-right: 0;
  }
}

.contact {
  margin-left: -50vw;
}

.contact__headline {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.contact__headline h2 {
  margin: 0;
}

.contact__headline__subline {
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  margin-bottom: .25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.contact__wrapper {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.contact__background {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  border-radius: .5rem;
  position: absolute;
  top: 0;
  bottom: 1rem;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .contact__background {
    bottom: 2rem;
  }
}

@media (min-width: 1024px) {
  .contact__background {
    bottom: 3rem;
  }
}

.contact__background {
  --tw-gradient-from: #002fa7;
  --tw-gradient-to: rgba(0, 47, 167, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #7c3aed;
}

.contact__form__hp-input {
  display: none;
}

.contact__form label {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.contact__form label.active > div, .contact__form label:hover > div {
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-width: 2px;
}

@media (min-width: 768px) {
  .contact__form label.active > div, .contact__form label:hover > div {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.contact__form label.active span, .contact__form label.active svg, .contact__form label:hover span, .contact__form label:hover svg {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.contact__form input:not([type="checkbox"]), .contact__form textarea {
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  border-radius: .25rem;
  padding: .75rem 1rem;
  line-height: 1.25;
  display: block;
}

.contact__form input:not([type="checkbox"]):focus, .contact__form textarea:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.contact__form input[type="checkbox"] {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  border-radius: .25rem;
  align-items: center;
  display: inline-flex;
}

.contact__form input[type="checkbox"] span {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  padding-left: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.contact__form__inputs {
  flex-direction: column;
  margin-bottom: 1rem;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

@media (min-width: 768px) {
  .contact__form__inputs {
    flex-direction: row;
  }
}

.contact__form__inputs__name {
  width: 100%;
  margin-bottom: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (min-width: 768px) {
  .contact__form__inputs__name {
    width: 50%;
    margin-bottom: 0;
  }
}

.contact__form__inputs__email {
  width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (min-width: 768px) {
  .contact__form__inputs__email {
    width: 50%;
  }
}

.contact__form__message, .contact__form__privacy {
  margin-bottom: 1rem;
}

.contact__form__error-message {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.contact__form__wrapper {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 0;
  position: relative;
}

@media (min-width: 768px) {
  .contact__form__wrapper {
    padding: 1.5rem 1.5rem 0;
  }
}

@media (min-width: 1024px) {
  .contact__form__wrapper {
    padding: 2.5rem 2.5rem 0;
  }
}

.contact__form__container {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
}

@media (min-width: 640px) {
  .contact__form__container {
    max-width: 42rem;
  }
}

@media (min-width: 768px) {
  .contact__form__container {
    padding: 2.5rem;
  }
}

.contact__form__contact {
  text-align: center;
  margin-top: 2rem;
}

.contact__form__contact p {
  margin-bottom: .5rem;
}

.contact__form__success svg {
  height: 5rem;
  width: 5rem;
  --tw-text-opacity: 1;
  color: rgba(22, 163, 74, var(--tw-text-opacity));
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.contact__form__success div {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.contact__form__success h2 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.contact__form__success a {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.contact__form__success a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.contact__form__failed svg {
  height: 5rem;
  width: 5rem;
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.contact__form__failed div {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.contact__form__failed h2 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.contact__form__failed a {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.contact__form__failed a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.contactWizard {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.contactWizard__wrapper {
  z-index: 10;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem 0;
  position: relative;
}

@media (min-width: 768px) {
  .contactWizard__wrapper {
    padding-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .contactWizard__wrapper {
    padding-bottom: 0;
  }
}

.contactWizard__wrapper > div {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  border-radius: .5rem;
}

.contactWizard__wrapper > div > div {
  max-width: 56rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

@media (min-width: 640px) {
  .contactWizard__wrapper > div > div {
    padding: 2rem;
  }
}

@media (min-width: 768px) {
  .contactWizard__wrapper > div > div {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.contactWizard__step {
  text-align: center;
}

.contactWizard__step__headline {
  flex-direction: column;
  margin-top: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

@media (min-width: 1024px) {
  .contactWizard__step__headline {
    margin-top: 2.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.contactWizard__step__headline h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.contactWizard__step__headline span {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-style: italic;
}

.contactWizard__step__text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contactWizard__step__customButton {
  margin-top: 2.5rem;
}

@media (min-width: 640px) {
  .contactWizard__step__customButton {
    margin-top: 2.5rem;
  }
}

.contactWizard__step__thankYou__text {
  margin-top: 2rem;
}

@media (min-width: 640px) {
  .contactWizard__step__thankYou__text {
    margin-top: 2.5rem;
  }
}

.contactWizard__step__wrapper {
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

@media (min-width: 768px) {
  .contactWizard__step__wrapper {
    width: 9rem;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1024px) {
  .contactWizard__step__wrapper {
    width: 11rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.contactWizard__step__checkbox {
  min-height: 14rem;
  flex-direction: column;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 640px) {
  .contactWizard__step__checkbox {
    min-height: 18rem;
  }
}

@media (min-width: 768px) {
  .contactWizard__step__checkbox {
    flex-flow: wrap;
    justify-content: center;
  }
}

.contactWizard__step__checkbox label {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.contactWizard__step__checkbox label.active > div {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-width: 2px;
}

@media (min-width: 768px) {
  .contactWizard__step__checkbox label.active > div {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.contactWizard__step__checkbox label.active > span {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.contactWizard__step__checkbox label.active svg {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.contactWizard__step__checkbox input {
  display: none;
}

.contactWizard__step__button {
  width: 100%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(230, 234, 246, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
  border-radius: .5rem;
  align-items: center;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

@media (min-width: 768px) {
  .contactWizard__step__button {
    width: auto;
    max-width: none;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
}

.contactWizard__step__button__icon {
  height: 2.5rem;
  width: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  margin: .5rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (min-width: 768px) {
  .contactWizard__step__button__icon {
    height: 4rem;
    width: 4rem;
    margin: 1rem;
  }
}

@media (min-width: 1024px) {
  .contactWizard__step__button__icon {
    margin: 1.5rem;
  }
}

.contactWizard__step__button__label {
  width: auto;
  cursor: pointer;
  text-align: left;
  margin-left: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-weight: 600;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (min-width: 768px) {
  .contactWizard__step__button__label {
    width: 10rem;
    text-align: center;
    margin-left: 0;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
}

.contactWizard__step__button__otherIcon {
  height: 2.5rem;
  width: 2.5rem;
  margin: .5rem;
}

@media (min-width: 768px) {
  .contactWizard__step__button__otherIcon {
    height: 4rem;
    width: 4rem;
    margin: 1rem;
  }
}

@media (min-width: 1024px) {
  .contactWizard__step__button__otherIcon {
    margin: 1.5rem;
  }
}

.contactWizard__step__contact {
  min-height: 14rem;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 640px) {
  .contactWizard__step__contact {
    min-height: 18rem;
  }
}

@media (min-width: 768px) {
  .contactWizard__step__contact {
    flex-flow: wrap;
  }
}

.contactWizard__step__contact__wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.contactWizard__step__contact__text {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  margin-bottom: 1.5rem;
}

.contactWizard__step__contact__formWrapper {
  flex-wrap: wrap;
  display: flex;
}

.contactWizard__step__contact__formInput {
  width: 100%;
  text-align: left;
  margin-bottom: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (min-width: 768px) {
  .contactWizard__step__contact__formInput {
    width: 24rem;
  }
}

.contactWizard__step__contact__formInput label {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  display: none;
}

.contactWizard__step__contact__formInput input {
  width: 100%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
  border-radius: .5rem;
  padding: .75rem 1rem;
  line-height: 1.25;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.contactWizard__step__contact__formInput input:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

@media (min-width: 768px) {
  .contactWizard__step__contact__formInput input {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
}

.contactWizard__step__contact__errorMessage {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.contactWizard__step__contact__disclaimer {
  max-width: 36rem;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: .75rem;
  line-height: 1rem;
}

@media (min-width: 640px) {
  .contactWizard__step__contact__disclaimer {
    margin-top: 2.5rem;
  }
}

.contactWizard__step__radio {
  min-height: 14rem;
  flex-direction: column;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 640px) {
  .contactWizard__step__radio {
    min-height: 18rem;
  }
}

@media (min-width: 768px) {
  .contactWizard__step__radio {
    flex-flow: wrap;
    justify-content: center;
  }
}

.contactWizard__step__radio input {
  display: none;
}

.contactWizard__step__radio__otherWrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.contactWizard__step__radio__otherWrapper span {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-weight: 600;
}

.contactWizard__step__radio__otherWrapper input {
  width: 100%;
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  padding: .75rem 1rem;
  font-weight: 500;
  display: block;
}

.contactWizard__step__radio__otherWrapper input:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.contactWizard__step__radio__otherButton {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

@media (min-width: 768px) {
  .contactWizard__step__radio__otherButton {
    max-width: none;
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
}

.contactWizard__step__radio label {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.contactWizard__response {
  min-height: 18rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contactWizard__progressBar {
  align-items: center;
  display: flex;
}

.contactWizard__progressBar__bar {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  border-radius: 9999px;
  margin-right: .5rem;
}

.contactWizard__progressBar__bar > div {
  height: .5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 9999px;
  font-size: .75rem;
  line-height: 1;
}

.contactWizard__progressBar__text {
  width: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.contactWizard__buttons {
  height: 2.5rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (min-width: 768px) {
  .contactWizard__buttons {
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-top: 1.25rem;
  }
}

.contactWizard__buttons__wrapper {
  justify-content: space-between;
  padding-bottom: 1.25rem;
  display: flex;
}

.contactWizard__buttons__prev {
  width: 50%;
  text-align: left;
  justify-content: flex-start;
  display: flex;
}

.contactWizard__buttons__next {
  width: 50%;
  text-align: right;
  justify-content: flex-end;
  display: flex;
}

.features {
  width: 100vw;
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  padding: 5rem 1rem;
  position: relative;
  left: 50%;
}

@media (min-width: 1024px) {
  .features {
    padding-left: 0;
    padding-right: 0;
  }
}

.features {
  margin-left: -50vw;
}

.features__wrapper {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 768px) {
  .features__wrapper {
    max-width: 64rem;
  }
}

.features__headline {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.features__headline__subline {
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  margin-bottom: .25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.features__items {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1rem;
  display: grid;
}

@media (min-width: 768px) {
  .features__items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .features__items {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.features__coming-soon__nav {
  justify-content: center;
  align-items: center;
  margin: 4rem auto 2.5rem;
  display: flex;
}

.features__coming-soon__nav > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)) );
}

.features__coming-soon__nav.active {
  position: relative;
}

.features__coming-soon__nav.active span {
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.features__coming-soon__nav.active svg {
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
}

.features__coming-soon__nav svg {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-width: 2px;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.features__coming-soon__nav span {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-width: 2px;
  font-weight: 700;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hero {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

@media (min-width: 1024px) {
  .hero {
    text-align: left;
  }
}

.hero__content {
  order: 1;
  margin-top: 2.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (min-width: 1024px) {
  .hero__content {
    width: 50%;
    order: 0;
    margin-top: 0;
    padding-right: 2.5rem;
  }
}

.hero__content__heading {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

@media (min-width: 768px) {
  .hero__content__heading {
    hyphens: unset;
  }
}

.hero__content__copy {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  margin-bottom: 2rem;
  line-height: 1.625;
}

.hero__content__image {
  padding-left: .5rem;
  padding-right: .5rem;
}

@media (min-width: 1024px) {
  .hero__content__image {
    width: 50%;
  }
}

.hero__list {
  margin-top: 1rem;
}

.hero__list > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.hero__list {
  text-align: left;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  .hero__list {
    padding-left: 2.5rem;
  }
}

.hero__list li {
  align-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  display: flex;
  position: relative;
}

.hero__list li:before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
}

.impressions {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.impressions__background {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  border-radius: .5rem;
  position: absolute;
  top: 0;
  bottom: 1rem;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .impressions__background {
    bottom: 2rem;
  }
}

@media (min-width: 1024px) {
  .impressions__background {
    bottom: 3rem;
  }
}

.impressions__background {
  --tw-gradient-from: #002fa7;
  --tw-gradient-to: rgba(0, 47, 167, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #7c3aed;
}

.impressions__image {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem 0;
  position: relative;
}

@media (min-width: 768px) {
  .impressions__image {
    padding: 1.5rem 1.5rem 0;
  }
}

@media (min-width: 1024px) {
  .impressions__image {
    padding: 2.5rem 2.5rem 0;
  }
}

.impressions__image img {
  width: 100%;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  margin-left: auto;
  margin-right: auto;
}

.pricing {
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
}

.pricing__wrapper {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 768px) {
  .pricing__wrapper {
    max-width: 64rem;
  }
}

.pricing__headline {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.pricing__headline__subline {
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  margin-bottom: .25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.pricing__content {
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media (min-width: 1024px) {
  .pricing__content {
    flex-direction: row;
  }
}

.pricing__content__item {
  width: 100%;
  display: flex;
}

.pricing__content__item > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.pricing__content__item {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  text-align: left;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  align-self: stretch;
  padding: 1.5rem;
}

@media (min-width: 1024px) {
  .pricing__content__item {
    width: 40%;
    padding: 3rem;
  }
}

.pricing__content__item__icon svg {
  height: 3rem;
  width: 3rem;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  padding: .25rem;
}

.pricing__content__item__text h3 {
  margin-top: .5rem;
}

.pricing__content__item__text p {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.pricing__content__plus {
  width: 100%;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  flex-shrink: 0;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

@media (min-width: 1024px) {
  .pricing__content__plus {
    width: 20%;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.pricing__content__plus svg {
  height: 4rem;
  width: 4rem;
}

.pricing__cta {
  margin-top: 5rem;
}

.pricing__cta__button {
  margin-top: 1rem;
}

.pricing__cta__button button {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .pricing__cta__button button {
    width: auto;
  }
}

.solutions {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
}

@media (min-width: 1024px) {
  .solutions {
    max-width: 72rem;
  }
}

.solutions__background {
  margin-bottom: -2rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .solutions__background {
    max-width: 115%;
    width: 115%;
    margin-bottom: -90%;
    margin-left: -3rem;
  }
}

.solutions__background img {
  max-height: 18rem;
  width: 100%;
  display: block;
}

.solutions__wrapper {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  display: grid;
}

@media (min-width: 1024px) {
  .solutions__wrapper {
    gap: 9rem;
  }
}

.solutions__wrapper > div:first-child {
  order: 9999;
  margin-bottom: 6rem;
}

.solutions__wrapper > div:nth-child(2) {
  margin-left: -4rem;
}

@media (min-width: 1024px) {
  .solutions__wrapper {
    grid-template-columns: 1fr 1.3fr;
  }

  .solutions__wrapper > div:first-child {
    order: -9999;
    margin-bottom: 0;
  }
}

.solutions__headline {
  text-align: left;
}

.solutions__text {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.solutions__text ul {
  margin-top: 1rem;
}

.solutions__text ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

@media (min-width: 1024px) {
  .solutions__text ul {
    padding-left: 2.5rem;
  }
}

.solutions__text ul li {
  align-content: flex-start;
  align-items: center;
  display: flex;
}

.solutions__text ul li svg {
  height: 1.5rem;
  width: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  flex-shrink: 0;
  align-self: start;
  justify-self: start;
  margin-right: .5rem;
}

.steps {
  width: 100vw;
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5rem 2rem;
  display: flex;
  position: relative;
  left: 50%;
}

@media (min-width: 1024px) {
  .steps {
    padding-left: 0;
    padding-right: 0;
  }
}

.steps {
  margin-left: -50vw;
}

.steps__headline {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.steps__headline__subline {
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgba(0, 47, 167, var(--tw-text-opacity));
  margin-bottom: .25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.use-case {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.use-case__background {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  border-radius: .5rem;
  position: absolute;
  top: 0;
  bottom: 1rem;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .use-case__background {
    bottom: 2rem;
  }
}

@media (min-width: 1024px) {
  .use-case__background {
    bottom: 3rem;
  }
}

.use-case__background {
  --tw-gradient-from: #002fa7;
  --tw-gradient-to: rgba(0, 47, 167, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #7c3aed;
}

.use-case__image {
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.use-case__image img {
  width: 100%;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: .5rem;
  margin-bottom: .5rem;
  margin-left: auto;
  margin-right: auto;
}

.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide--swipeable {
  cursor: grab;
}

.glide__slide {
  padding: 1rem 1rem 0;
}

@media (min-width: 768px) {
  .glide__slide {
    padding: 1.5rem 1.5rem 0;
  }
}

@media (min-width: 1024px) {
  .glide__slide {
    padding: 2.5rem 2.5rem 0;
  }
}

.glide__arrows {
  display: none;
}

@media (min-width: 1280px) {
  .glide__arrows {
    display: block;
  }
}

.glide__bullets {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  list-style-type: none;
  display: inline-flex;
  position: absolute;
  bottom: -2rem;
  left: 50%;
}

.glide__bullet {
  height: .5rem;
  width: .5rem;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(204, 213, 237, var(--tw-bg-opacity));
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 9999px;
  margin-left: .25rem;
  margin-right: .25rem;
  padding: 0;
  line-height: 1;
}

.glide__bullet:focus {
  --tw-border-opacity: 1;
  border-color: rgba(204, 213, 237, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(204, 213, 237, var(--tw-bg-opacity));
}

.glide__bullet:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 47, 167, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
}

.glide__bullet--active {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 47, 167, var(--tw-bg-opacity));
}

.glide__bullet--active:hover, .glide__bullet--active:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 38, 134, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(0, 38, 134, var(--tw-bg-opacity));
}

.glide__arrow {
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  border-radius: 9999px;
  align-items: center;
  padding: .75rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 50%;
}

.glide__arrow:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.glide__arrow:before {
  height: 1.5rem;
  width: 1.5rem;
  content: "";
  display: inline-block;
}

.glide__arrow--left {
  left: -1.5rem;
}

.glide__arrow--left:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M7 16l-4-4m0 0l4-4m-4 4h18' /%3E%3C/svg%3E");
}

.glide__arrow--right {
  right: -1.5rem;
}

.glide__arrow--right:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M17 8l4 4m0 0l-4 4m4-4H3' /%3E%3C/svg%3E");
}

.glide__arrow--disabled {
  display: none;
}

/*# sourceMappingURL=index.ba0f3792.css.map */
