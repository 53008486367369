// #pricing {
//   .pricing__hero__list {
//     @apply space-y-2 mt-6 pl-8;

//     li {
//       @apply flex items-center content-start relative pl-8;

//       &::before {
//         content: "";
//         @apply block h-6 w-6 bg-no-repeat absolute left-0 top-0 transform transition;
//       }

//       &.phone::before {
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z' /%3E%3C/svg%3E");
//       }

//       &.mail::before {
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z' /%3E%3C/svg%3E");
//       }
//     }
//   }
// }

.pricing_new {
  @apply relative left-1/2 mx-auto flex text-center flex-col justify-center items-center w-screen bg-gray-50 py-20 px-8 lg:px-0;
  margin-left: -50vw;
}

.bookings-slider {
  accent-color: #002fa7;
}

#pricing section {
  @apply pt-2;
}

[x-cloak] {
  display: none !important;
}
