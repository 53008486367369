#integration {
  .academy {
    @apply rounded-lg shadow bg-gray-50 mt-16 lg:my-16 flex flex-col lg:flex-row justify-center p-6 lg:p-10 relative;

    &__image {
      @apply flex-shrink-0 w-auto mx-auto mb-6 lg:w-1/2 lg:mt-0 lg:-mb-72;
    }

    &__content {
      @apply text-center lg:text-left;
      h3 {
        @apply mt-0;
      }
      p {
        @apply text-gray-500 text-base lg:text-lg;
      }

      button {
        @apply mt-6 lg:mt-4;
      }
    }
  }
}
