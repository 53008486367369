#flexibility {
  .flexibility__examples {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-16;

    &__column {
      h2 {
        @apply text-left;
      }
    }
  }
}
