.steps {
  @apply relative left-1/2 mx-auto flex text-center flex-col justify-center items-center w-screen bg-gray-50 py-20 px-8 lg:px-0;
  margin-left: -50vw;

  &__headline {
    @include headline();

    &__subline {
      @include subline();
    }
  }
}
