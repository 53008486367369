.tabs {
  @apply mt-12;
  &__nav {
    @apply flex flex-wrap justify-between lg:justify-start mb-4;

    &__item {
      @apply flex p-2 text-gray-900 font-semibold items-center hover:no-underline cursor-pointer transition;
      &__title {
        @apply border-b-2 border-gray-200 transition;
      }

      &__icon {
        @apply hidden lg:block w-8 h-8;
      }

      &.is-active {
        @apply relative;

        span {
          @apply border-primary-500 text-primary-500;
        }

        &:after {
          @screen lg {
            @apply block left-1/2 w-0 h-0 z-10 absolute -bottom-4;
            content: "";
            border-color: transparent transparent #f9fafb;
            border-style: solid;
            border-width: 0 1rem 1rem;
          }
        }
      }
    }
  }

  &__container {
    @apply rounded-lg shadow bg-gray-50 flex flex-col lg:flex-row justify-center p-6 lg:p-10 relative;

    &__tab {
      @apply text-left w-full;
      h3 {
        @apply mt-0;
      }
      p,
      ul {
        @apply text-base lg:text-lg text-gray-500;
      }

      ul {
        @apply space-y-2 mt-6;

        li {
          @apply flex items-center content-start relative pl-8;

          &::before {
            content: "";
            @apply block h-6 w-6 bg-no-repeat absolute left-0 -top-px transform transition;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
          }
        }
      }

      button {
        @apply mt-6 lg:mt-4;
      }
    }
  }
}
