.contact {
  @apply relative left-1/2 mx-auto w-screen bg-primary-500 py-20 px-4 lg:px-0;
  margin-left: -50vw;

  &__headline {
    @apply text-white;

    h2 {
      @apply m-0;
    }

    &__subline {
      @include subline();
    }
  }

  &__wrapper {
    @apply relative mx-auto flex justify-center items-center;
  }

  &__background {
    @include background(from-primary);
  }

  &__form {
    &__hp-input {
      display: none;
    }

    label {
      @apply text-gray-500 text-sm font-semibold;

      &.active,
      &:hover {
        > div {
          @apply border-primary-500;
          @apply border-2;
          @apply scale-105;

          @screen md {
            @apply scale-110;
          }
        }

        span,
        svg {
          @apply text-primary-500;
        }
      }
    }

    input:not([type="checkbox"]),
    textarea {
      @apply appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-primary-500 rounded focus:outline-none;
    }

    input[type="checkbox"] {
      @apply border border-gray-200 rounded bg-gray-50 text-primary-500 inline-flex items-center;

      span {
        @apply pl-2 text-sm text-gray-500;
      }
    }

    &__inputs {
      @apply flex flex-col md:flex-row mb-4 -mx-2;

      &__name {
        @apply w-full md:w-1/2 px-2 mb-4 md:mb-0;
      }

      &__email {
        @apply w-full md:w-1/2 px-2;
      }
    }

    &__message,
    &__privacy {
      @apply mb-4;
    }

    &__error-message {
      @apply text-sm text-red-600;
    }

    &__wrapper {
      @apply relative mx-auto z-10 py-4 md:p-6 lg:p-10 pb-0 md:pb-0 lg:pb-0;
    }

    &__container {
      @apply w-full sm:max-w-2xl mx-auto bg-white rounded-lg shadow p-6 md:p-10;
    }

    &__contact {
      @apply text-center mt-8;

      p {
        @apply mb-2;
      }
    }

    &__success {
      @include contact-form-response(success);
    }

    &__failed {
      @include contact-form-response(failed);
    }
  }
}
