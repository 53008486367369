.hotspot {
  &__spot {
    @apply absolute flex justify-center h-3 w-3 sm:h-4 sm:w-4 lg:h-6 lg:w-6 shadow-lg rounded-full text-white bg-secondary-500 transition ring-2 sm:ring-3 ring-secondary-100 ring-opacity-50 duration-300;

    &:first-of-type {
      &.is-animated {
        @apply ring-0;

        .hotspot__spot__ping {
          @apply animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-secondary-500 opacity-50;
        }
      }
    }

    &:hover {
      @apply bg-secondary-600 cursor-pointer ring-0;

      &:before {
        @apply visible opacity-100;
      }
    }

    &.is-active {
      @apply bg-secondary-600 ring-0;

      .hotspot__spot__ping {
        @apply animate-none;
      }

      &:hover {
        @apply bg-secondary-700;
      }

      &:before {
        @apply sm:visible sm:opacity-100;
      }
    }

    &:before {
      content: attr(data-tooltip);
      @apply invisible opacity-0 block absolute whitespace-nowrap -top-12 bg-black bg-opacity-80 text-white text-sm font-bold px-3 py-2 rounded-lg transition-all duration-300;
    }
  }

  &__close {
    @apply cursor-pointer float-right h-5 w-5 sm:h-6 sm:w-6 transition-all rounded-full bg-white;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");

    &:hover {
      @apply bg-gray-300;
    }
  }

  &__content {
    @apply rounded-lg absolute flex flex-col w-full h-full bg-black bg-opacity-80 text-white p-4 md:p-6 lg:p-10 z-10 overflow-x-auto sm:overflow-hidden sm:rounded-none sm:h-auto;

    &.top {
      @apply top-0 rounded-t-lg;
    }

    &.bottom {
      @apply bottom-0 rounded-b-lg;
    }

    h3 {
      @apply text-xl sm:text-2xl my-0;
    }

    p {
      @apply text-base sm:text-lg mb-3 sm:mb-0;
    }
  }

  &__header {
    @apply flex flex-row-reverse items-center justify-between mb-3;
  }

  &__navigation {
    @apply flex justify-between w-28 sm:w-36 mx-auto mt-auto md:mt-4 items-end;

    > div:not(.hotspot__navigation--pagination) {
      @apply cursor-pointer flex items-center rounded-full shadow-md bg-white p-1.5;

      &:before {
        @apply h-3 w-3 sm:h-4 sm:w-4 inline-block;
        content: "";
      }
    }

    &--pagination {
      @apply flex items-center text-neutral-400 text-sm sm:text-base;
    }

    &--prev {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 19l-7-7 7-7' /%3E%3C/svg%3E");
      }
    }

    &--next {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 5l7 7-7 7' /%3E%3C/svg%3E");
      }
    }
  }
}
