.hero {
  @apply flex flex-wrap text-center justify-center lg:text-left -mx-2;

  &__content {
    @apply lg:w-1/2 px-2 lg:pr-10 mt-10 lg:mt-0 order-1 lg:order-none;

    &__heading {
      hyphens: auto;

      @screen md {
        hyphens: unset;
      }
    }

    &__copy {
      @apply mb-8 text-gray-500 leading-relaxed;
    }

    &__image {
      @apply lg:w-1/2 px-2;
    }
  }

  &__list {
    @include list(true, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4'/%3E%3C/svg%3E"));
  }
}
