body {
  @apply text-lg antialiased font-normal overflow-x-hidden;
}

main {
  @apply flex-1;
}

.container {
  @apply max-w-6xl min-h-screen flex flex-col mx-auto mt-28;
}

h1 {
  @apply text-4xl sm:text-5xl mb-6 leading-tight font-black;
}

h2 {
  @apply text-3xl sm:text-4xl mb-8 font-extrabold text-center;
}

h3 {
  @apply text-2xl my-3 font-bold;
}

a {
  @apply text-primary-500 hover:text-primary-700;
}

section {
  @apply py-12 lg:py-16 px-4;
}

[x-cloak] {
  display: none !important;
}

strong {
  @apply font-bold text-gray-900;
}

.text-highlight {
  @apply font-semibold text-primary-300;
}
