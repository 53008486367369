.contactWizard {
  @apply text-center relative mx-auto flex justify-center items-center;

  // &__background {
  //   @include background(to-primary);
  // }

  &__wrapper {
    @apply w-full relative mx-auto z-10 p-4 pb-0 md:pb-0 lg:pb-0;

    > div {
      @apply bg-gray-50 rounded-lg shadow w-full;
      hyphens: none;
      -ms-hyphens: none;

      > div {
        @apply max-w-4xl mx-auto md:py-12 sm:p-8 p-4;
      }
    }
  }

  &__step {
    @apply text-center;

    &__headline {
      @apply flex flex-col mt-4 py-2 lg:mt-10 lg:py-5;

      h2 {
        @apply my-0;
      }

      span {
        @apply italic text-gray-500 py-1;
      }
    }

    &__text {
      @apply flex flex-col items-center justify-center;
    }

    &__customButton {
      @apply sm:mt-10 mt-10;
    }

    &__thankYou {
      &__text {
        @apply sm:mt-10 mt-8;
      }
    }

    &__wrapper {
      @apply w-full mb-4 md:mb-0 md:w-36 lg:w-44 flex flex-col items-center md:px-4 lg:px-8;
    }

    &__checkbox {
      @apply mx-auto flex flex-col md:flex-wrap md:flex-row md:justify-center content-center min-h-56 sm:min-h-72;

      label {
        @apply w-full flex flex-col items-center;

        &.active {
          > div {
            @apply border-2 scale-105 md:scale-110 bg-primary-500 text-white;
          }

          > span {
            @apply text-primary-500;
          }

          svg {
            @apply text-white;
          }
        }
      }

      input {
        @apply hidden;
      }
    }

    &__button {
      @apply w-full md:w-auto flex items-center md:max-w-none rounded-lg shadow md:shadow-lg border-2 border-transparent cursor-pointer transition duration-300 ease-in-out transform bg-primary-50;

      &__icon {
        @apply w-10 h-10 md:w-16 md:h-16 m-2 md:m-4 lg:m-6 transition duration-300 ease-in-out text-primary-500;
      }

      &__label {
        @apply w-auto cursor-pointer font-semibold md:w-40 py-2 md:pt-4 md:pb-8 ml-2 md:ml-0 text-left md:text-center transition duration-300 ease-in-out;
      }

      &__otherIcon {
        @apply w-10 h-10 md:w-16 md:h-16 m-2 md:m-4 lg:m-6;
      }
    }

    &__contact {
      @apply mx-auto flex flex-col md:flex-wrap md:flex-row justify-center min-h-56 sm:min-h-72;

      &__wrapper {
        @apply flex flex-col items-center;
      }

      &__text {
        @apply text-gray-500 mb-6;
      }

      &__formWrapper {
        @apply flex flex-wrap;
      }

      &__formInput {
        @apply px-2 mb-6 text-left w-full md:w-96;

        label {
          @apply text-gray-500 text-sm font-semibold hidden;
        }

        input {
          @apply appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-white focus:border-primary-500 rounded-lg shadow md:shadow-lg border-2 border-transparent focus:outline-none transition transform duration-300 ease-in-out focus:scale-110;
        }
      }

      &__errorMessage {
        @apply text-sm text-red-600;
      }

      &__disclaimer {
        @apply text-xs text-gray-500 max-w-xl sm:mt-10;
      }
    }

    &__radio {
      @apply mx-auto flex flex-col md:flex-wrap md:flex-row md:justify-center content-center min-h-56 sm:min-h-72;

      input {
        @apply hidden;
      }

      &__otherWrapper {
        @apply flex flex-col items-center;

        span {
          @apply font-semibold text-primary-500 mb-4;
        }

        input {
          @apply block w-full px-4 py-3 rounded-lg shadow focus:outline-none text-gray-600 font-medium;
        }
      }

      &__otherButton {
        @apply flex items-center md:max-w-none mb-4 rounded-lg shadow md:shadow-lg border-transparent border-primary-500 border-2 scale-105 md:scale-110;
      }

      label {
        @apply w-full flex flex-col items-center;
      }
    }
  }

  &__response {
    @apply min-h-72 flex justify-center items-center;
  }

  &__progressBar {
    @apply flex items-center;

    &__bar {
      @apply w-full bg-gray-200 rounded-full mr-2;

      > div {
        @apply rounded-full bg-primary-500 text-xs leading-none h-2 text-center text-white;
      }
    }

    &__text {
      @apply text-xs w-10 text-gray-500 font-bold;
    }
  }

  &__buttons {
    @apply h-10 md:pt-5  md:mx-8 md:mb-4 my-2;

    &__wrapper {
      @apply flex justify-between pb-5;
    }

    &__prev {
      @apply w-1/2 text-left flex justify-start;
    }

    &__next {
      @apply w-1/2 text-right flex justify-end;
    }
  }
}
