footer {
  @apply text-sm lg:py-20 py-10 px-4;

  .footer {
    @apply flex flex-col lg:flex-row items-center;

    &__content {
      @apply w-full lg:w-auto lg:mr-auto text-center lg:text-left flex flex-col lg:flex-row;

      address {
        @apply not-italic;
      }

      a {
        @apply text-primary-500 hover:underline;
      }

      &__item {
        @apply lg:ml-8 lg:mt-0 mt-4;

        li {
          &:nth-child(1) {
            @apply mb-2 sm:mb-0;
          }
        }
      }
    }

    &__image {
      @apply flex justify-center items-center space-x-6 mt-4 lg:mt-0;
    }
  }
}
