@mixin headline() {
  @apply flex flex-col items-center;
}

@mixin subline() {
  @apply uppercase text-primary-500 font-bold text-sm mb-1 text-center;
}

@mixin contact-form-response($type) {
  svg {
    @apply h-20 w-20 mx-auto mb-4;

    @if $type == success {
      @apply text-green-600;
    } @else if $type == failed {
      @apply text-red-600;
    }
  }

  div {
    @apply text-gray-500 text-center;
  }

  h2 {
    @apply text-gray-900;
  }

  a {
    @apply text-primary-500 hover:underline;
  }
}

@mixin background($type: from-primary) {
  @apply absolute inset-0 bottom-4 md:bottom-8 lg:bottom-12 bg-gradient-to-br rounded-lg;

  @if $type == to-primary {
    @apply from-purple-600 to-primary-500;
  } @else if $type == from-primary {
    @apply from-primary-500 to-purple-600;
  }
}

@mixin list($pl: true, $url) {
  @apply mt-4 space-y-2 text-gray-500 text-left;

  @if $pl == true {
    @apply lg:pl-10;
  } @else if $pl == false {
    @apply pl-0;
  }

  li {
    @apply flex items-center content-start relative pl-8;

    &::before {
      content: "";
      @apply block h-6 w-6 bg-no-repeat absolute left-0 top-2px transform transition;
      background-image: $url;
    }
  }
}
