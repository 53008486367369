@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.glide {
  &--swipeable {
    @apply cursor-grab;
  }

  &__slide {
    @apply p-4 md:p-6 lg:p-10 pb-0 md:pb-0 lg:pb-0;
  }

  &__arrows {
    @apply hidden xl:block;
  }

  &__bullets {
    @apply absolute left-1/2 -translate-x-1/2 list-none inline-flex -bottom-8;
  }

  &__bullet {
    @apply w-2 h-2 p-0 rounded-full border-2 border-transparent cursor-pointer leading-none mx-1 bg-primary-100;

    &:focus {
      @apply bg-primary-100 border-primary-100;
    }

    &:hover {
      @apply bg-primary-500 border-primary-500;
    }

    &--active {
      @apply bg-primary-500;

      &:hover,
      &:focus {
        @apply bg-primary-700 border-primary-700;
      }
    }
  }

  &__arrow {
    @apply absolute top-1/2 p-3 rounded-full shadow cursor-pointer -translate-y-1/2 bg-white flex items-center transition-colors;

    &:hover {
      @apply bg-gray-100;
    }

    &:before {
      @apply inline-block w-6 h-6;
      content: "";
    }

    &--left {
      @apply -left-6;

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M7 16l-4-4m0 0l4-4m-4 4h18' /%3E%3C/svg%3E");
      }
    }

    &--right {
      @apply -right-6;

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M17 8l4 4m0 0l-4 4m4-4H3' /%3E%3C/svg%3E");
      }
    }

    &--disabled {
      @apply hidden;
    }
  }
}
