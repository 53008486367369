@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("/fonts/36EE5B_1_0.eot");
  src: url("/fonts/36EE5B_1_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/36EE5B_1_0.woff2") format("woff2"),
    url("/fonts/36EE5B_1_0.woff") format("woff"),
    url("/fonts/36EE5B_1_0.ttf") format("truetype");
}

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("/fonts/36EE5B_7_0.eot");
  src: url("/fonts/36EE5B_7_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/36EE5B_7_0.woff2") format("woff2"),
    url("/fonts/36EE5B_7_0.woff") format("woff"),
    url("/fonts/36EE5B_7_0.ttf") format("truetype");
}

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("/fonts/36EE5B_F_0.eot");
  src: url("/fonts/36EE5B_F_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/36EE5B_F_0.woff2") format("woff2"),
    url("/fonts/36EE5B_F_0.woff") format("woff"),
    url("/fonts/36EE5B_F_0.ttf") format("truetype");
}

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("/fonts/36EE5B_3_0.eot");
  src: url("/fonts/36EE5B_3_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/36EE5B_3_0.woff2") format("woff2"),
    url("/fonts/36EE5B_3_0.woff") format("woff"),
    url("/fonts/36EE5B_3_0.ttf") format("truetype");
}

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("/fonts/36EE5B_B_0.eot");
  src: url("/fonts/36EE5B_B_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/36EE5B_B_0.woff2") format("woff2"),
    url("/fonts/36EE5B_B_0.woff") format("woff"),
    url("/fonts/36EE5B_B_0.ttf") format("truetype");
}

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("/fonts/36EE5B_B_0.eot");
  src: url("/fonts/36EE5B_5_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/36EE5B_5_0.woff2") format("woff2"),
    url("/fonts/36EE5B_5_0.woff") format("woff"),
    url("/fonts/36EE5B_5_0.ttf") format("truetype");
}
