.use-case {
  @apply relative mx-auto flex justify-center items-center;

  &__background {
    @include background(from-primary);
  }

  &__image {
    @apply relative mx-auto z-10;

    img {
      @apply mb-2 mx-auto w-full rounded-lg shadow;
    }
  }
}
